<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="常见问题"
                left-arrow
                @click-left="$router.go(-1)"
        />

        <Collapse v-model="activeName" accordion>

                <CollapseItem title="1.注册问卷无法保存" name="1">
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp;
                        注册问卷基本信息为必填项，请检查是否有题目未填写。
                    </p>
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 为保证专科问卷和健康日记与基本信息对应，注册问卷只可保存一次，请在【个人中心】-【问卷】中查询是否填写过注册问卷。
                    </p>

                </CollapseItem>
                <CollapseItem title="2.微信中找不到关节通？" name="2">
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 微信中点击【通讯录】-【公众号】在列表中查找或者直接点&nbsp;<i class="fa fa-search"></i>&nbsp;输入'关节通'。
                    </p>
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 为方便查找打开公众号，建议置顶关节通公众号，操作步骤见下图。
                    </p>
                    <vimg :src="require('../../img/faq/q2img1.png')" style="width: 45%"/>
                    <vimg :src="require('../../img/faq/q2img2.png')" style="width: 45%"/>

                </CollapseItem>
                <CollapseItem title="3.打不开训练视频？" name="3">
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 由于微信缓存原因个别用户推送链接无法打开，按下图操作清理微信缓存。清理缓存无法解决，请点击【个人中心】-【问题反馈】填写提交反馈内容。
                    </p>
                    <vimg :src="require('../../img/faq/q3img1.png')"  style="width: 45%"/>
                    <vimg :src="require('../../img/faq/q3img2.png')"  style="width: 45%"/>
                    <vimg :src="require('../../img/faq/q3img3.png')"  style="width: 45%"/>
                    <vimg :src="require('../../img/faq/q3img4.png')"  style="width: 45%"/>

                </CollapseItem>
                <CollapseItem title="4.沟通功能异常，无法与医生取得联系？" name="4">
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 请在【个人中心】-【问卷】中查询是否填写注册问卷，如未填写请点击关注公众号后推送的注册问卷消息或联系医生给您推送。已经填写注册问卷但无法与医生取得联系，请点击【个人中心】-【问题反馈】填写提交反馈内容。
                    </p>

                </CollapseItem>
                <CollapseItem title="5.如何上传视频和图片给医生？" name="5">
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 关节通主界面点击【沟通】直接给医生发送肢体伤口、功能情况图像和影像资料。
                    </p>
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 点击【个人中心】【图像和小视频】上传或查看肢体伤口、功能情况和影像资料。
                    </p>

                </CollapseItem>
                <CollapseItem title="6.如何分享公众号给好友？" name="6">
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 进入【个人中心】【我的医生】选择需要给朋友推荐的医生点咨询，把弹出的二维码截图发给朋友，朋友扫码关注。
                    </p>
                    <p>
                        <Icon name="star-o" />&nbsp;&nbsp; 按下图操作公众号主页面点击，然后在关注页面在点击，点击【推荐给朋友】选择相应的联系人。
                    </p>
                    <vimg :src="require('../../img/faq/q6img1.png')"  style="width: 45%"/>
                    <vimg :src="require('../../img/faq/q6img2.png')"  style="width: 45%"/>

                </CollapseItem>
        </Collapse>

        <RLFooter/>
    </div>
</template>

<script>

    import {Collapse, CollapseItem ,Icon,Image as vimg} from 'vant';

    export default {
        name: 'QueIdx',
        components: {
            Collapse, CollapseItem,Icon,vimg
        },
        data(){
            return {
                active:0,
                activeName:'-1'

            };
        },
        computed:{

        },
        methods:{

        }
        ,
        created() {
        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
